<template>
  <div>
    <b-card
      title="เเพ้ชนะสมาชิก"
    >
      <b-form
        style="max-width: 1024px;"
        @submit.prevent
      >
        <b-row class="mt-1 text-left align-items-top align-content-start">
          <b-col
            cols="12"
            md="5"
          >
            <b-form-group
              label="ยูสเซอร์เนม"
              label-for="mc-username"
              label-cols-lg="3"
              label-cols-md="4"
            >
              <b-form-input
                id="mc-username"
                v-model="form.username"
                placeholder="Username"
                @keydown.enter="onSubmit"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="5"
          >
            <b-form-group
              label="Round Id"
              label-for="RoundId"
              label-cols-lg="3"
              label-cols-md="4"
            >
              <b-form-input
                id="RoundId"
                v-model="form.roundId"
                placeholder="Round Id"
                @keydown.enter="onSubmit"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="5"
          >
            <b-form-group
              label="วันที่ออกบิล"
              label-for="mc-start"
              label-cols-lg="3"
              label-cols-md="4"
            >
              <flat-pickr
                v-model="startDate"
                class="form-control"
                :config="{ disableMobile:true,enableTime: true,dateFormat: 'd/m/Y H:i:S' , enableSeconds:true , time_24hr:true}"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="5"
          >
            <b-form-group
              label="ถึงวันที่"
              label-for="mc-ebd"
              label-cols-lg="3"
              label-cols-md="4"
            >
              <flat-pickr
                v-model="endDate"
                class="form-control"
                :config="{ disableMobile:true,enableTime: true,dateFormat: 'd/m/Y H:i:S' , enableSeconds:true , time_24hr:true}"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="2"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              @click.prevent="onSubmit"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    <b-card
      title=""
    >
      <div class="checkBoxInline mb-1">
        <span class="mr-1">โหมดเเสดงผล</span>
        <b-form-radio
          v-model="viewMode"
          name="simpleMode"
          value="simple"
          class="mr-1"
          @change="changeViewMode"
        >
          Simple
        </b-form-radio>
        <b-form-radio
          v-model="viewMode"
          name="advanceMode"
          value="advance"
          class=""
          @change="changeViewMode"
        >
          Advance
        </b-form-radio>
      </div>
      <b-table
        v-if="viewMode === 'simple'"
        ref="tableSimple"
        responsive
        :fields="columnsSimple"
        :per-page="pagination.rowsPerPage"
        :current-page="pagination.page"
        :items="onRequest"
        :filter="filter"
        bordered
      >
        <template #cell(username)="v">
          <span
            style="text-decoration: underline;cursor: pointer"
            class="font-weight-bolder"
            @click="showUserDetial(v.value)"
          >
            {{ v.value }}
          </span>
        </template>
        <template
          #cell(bet)="v"
        >
          <span class="font-weight-bolder"> {{ v.value }}</span>
        </template>
        <template #cell(winAmount)="v">
          <span
            v-if="v.item.status === -1 || v.item.status === 0 || v.item.status === -2"
            class="font-weight-bolder"
          >  {{ toNumber(v.value.toFixed(2)) }} </span>
          <span
            v-else-if="v.item.status === 1"
            class="text-success font-weight-bolder"
          >  {{ toNumber(v.value.toFixed(2)) }} </span>
          <span
            v-else-if="v.item.status === 2"
            class="text-danger font-weight-bolder"
          >  {{ toNumber(v.value.toFixed(2)) }} </span>
        </template>
        <template
          #cell(winLose)="v"
        >
          <span
            :class="textColor(v.value)"
            class="font-weight-bolder"
          > {{ toNumber(v.value.toFixed(2)) }}</span>
        </template>
        <template #cell(agent)="v">
          <span :class="textColor(v.value)"> {{ toNumber(v.value.toFixed(2)) }}</span>
        </template>
        <template #cell(company)="v">
          <span :class="textColor(v.value)"> {{ toNumber(v.value.toFixed(2)) }}</span>
        </template>
        <template #custom-foot>
          <tr v-if="summation.bet !== 0">
            <td />
            <td />
            <td />
            <td />
            <td />
            <td class="text-right">
              รวม
            </td>
            <td><span class="font-weight-bolder">{{ summation.bet }}</span></td>
            <td>{{ summation.before }}</td>
            <td>
              <span
                class="font-weight-bolder"
                :class="textColorWithTwoColor(summation.winAmount)"
              >  {{ toNumber(summation.winAmount.toFixed(2)) }} </span>
            </td>
            <td>{{ summation.after }}</td>
            <td>
              <span
                :class="textColor(summation.winlose)"
                class="font-weight-bolder"
              > {{ toNumber(summation.winlose.toFixed(2)) }}</span>
            </td>
            <td>
              <span :class="textColor(summation.agent)"> {{ toNumber(summation.agent.toFixed(2)) }}</span>
            </td>
            <td>
              <span :class="textColor(summation.company)"> {{ toNumber(summation.company.toFixed(2)) }}</span>
            </td>
          </tr>
          <tr v-else>
            <td colspan="11">
              No data available
            </td>
          </tr>
        </template>
      </b-table>

      <b-table
        v-if="viewMode === 'advance'"
        ref="tableAdvance"
        responsive
        :fields="columnsAdvance"
        :per-page="pagination.rowsPerPage"
        :current-page="pagination.page"
        :items="onRequest"
        :filter="filter"
        bordered
      >
        <template #cell(username)="v">
          <div>
            <span
              style="text-decoration: underline;cursor: pointer"
              class="font-weight-bolder"
              @click="showUserDetial(v.value)"
            >{{ v.value }}</span><br>@{{ v.item.prefix }}
          </div>
        </template>
        <template #cell(bet)="v">
          <span class="d-flex justify-content-between">
            <span>Before</span>
            <span class="pl-2">  {{ toNumber(v.item.beforeBet.toFixed(2)) }} </span>
          </span>
          <span class="d-flex justify-content-between font-weight-bolder">
            <span>Bet</span>
            <span class="pl-2">  {{ toNumber(v.item.bet.toFixed(2)) }} </span>
          </span>
          <span class="d-flex justify-content-between">
            <span>After</span>
            <span class="pl-2">  {{ toNumber(v.item.afterBet.toFixed(2)) }} </span>
          </span>
        </template>
        <template #cell(winAmount)="v">
          <span class="d-flex justify-content-between">
            <span>Before</span>
            <span class="pl-2">  {{ toNumber(v.item.before.toFixed(2)) }} </span>
          </span>
          <span class="d-flex justify-content-between font-weight-bolder">
            <span>Amount</span>
            <span
              v-if="v.item.status === -1"
              class="pl-2"
            >  {{ toNumber(v.value.toFixed(2)) }} </span>
            <span
              v-else-if="v.item.status === 1"
              class="pl-2 text-success"
            >  {{ toNumber(v.value.toFixed(2)) }} </span>
            <span
              v-else-if="v.item.status === 2"
              class="pl-2 text-danger"
            >  {{ toNumber(v.value.toFixed(2)) }} </span>
          </span>
          <span class="d-flex justify-content-between">
            <span>After</span>
            <span class="pl-2">  {{ toNumber(v.item.after.toFixed(2)) }} </span>
          </span>
        </template>
        <template #cell(status)="v">
          <div>
            <b-badge
              v-if="v.item.status === -1 || v.item.status === 0 || v.item.status === -2"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="light-warning"
            >Tie
            </b-badge>
            <b-badge
              v-else-if="v.value === 1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="light-success"
            >Win
            </b-badge>
            <b-badge
              v-else-if="v.value === 2"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="light-danger"
            >Lose
            </b-badge>
          </div>
        </template>
        <template
          #cell(winLose)="v"
        >
          <span
            :class="textColor(v.value)"
            class="font-weight-bolder"
          > {{ v.value.toFixed(2) }}</span>
        </template>
        <template #cell(agent)="v">
          <span :class="textColor(v.value)"> {{ v.value.toFixed(2) }}</span>
        </template>
        <template #cell(company)="v">
          <span :class="textColor(v.value)"> {{ v.value.toFixed(2) }}</span>
        </template>
        <template #custom-foot>
          <tr v-if="summation.bet !== 0">
            <td />
            <td />
            <td />
            <td />
            <td />
            <td class="text-right">
              รวม
            </td>
            <td>
              <span class="d-flex justify-content-between">
                <span>Before</span>
                <span class="pl-2">  {{ summation.beforeBet }} </span>
              </span>
              <span class="d-flex justify-content-between font-weight-bolder">
                <span>Bet</span>
                <span class="pl-2">  {{ summation.bet }} </span>
              </span>
              <span class="d-flex justify-content-between">
                <span>After</span>
                <span class="pl-2">  {{ summation.afterBet }} </span>
              </span>
            </td>
            <td>
              <span class="d-flex justify-content-between">
                <span>Before</span>
                <span class="pl-2">  {{ summation.before }} </span>
              </span>
              <span class="d-flex justify-content-between font-weight-bolder">
                <span>Amount</span>
                <span
                  class="pl-2"
                  :class="textColorWithTwoColor(summation.winAmount)"
                >  {{ toNumber(summation.winAmount.toFixed(2)) }} </span>
              </span>
              <span class="d-flex justify-content-between">
                <span>After</span>
                <span class="pl-2">  {{ summation.after }} </span>
              </span>
            </td>
            <td>
              <span
                :class="textColor(summation.winlose)"
                class="font-weight-bolder"
              > {{ toNumber(summation.winlose.toFixed(2)) }}</span>
            </td>
            <td>
              <span :class="textColor(summation.agent)"> {{ toNumber(summation.agent.toFixed(2)) }}</span>
            </td>
            <td>
              <span :class="textColor(summation.company)"> {{ toNumber(summation.company.toFixed(2)) }}</span>
            </td>
          </tr>
          <tr v-else>
            <td colspan="11">
              No data available
            </td>
          </tr>
        </template>
      </b-table>

      <b-row>
        <b-col
          cols="2"
        >
          <b-form-group class="text-nowrap">
            <label class="d-inline-block  mr-50 text-sm-right font-small-4">Per page</label>
            <b-form-select
              v-model="pagination.rowsPerPage"
              size="sm"
              :options="rowsOptions"
              class="w-50"
              style="min-width: 100px"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          lg="8"
          md="6"
          sm="12"
        >
          <b-pagination
            v-model="pagination.page"
            :total-rows="pagination.rowsNumber"
            :per-page="pagination.rowsPerPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable vue/no-template-shadow,vue/no-unused-components */
import {
  BFormSelect,
  BButton,
  BBadge,
  BCard,
  BCardBody,
  BCardText,
  BCol,
  BForm,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BRow,
  BTable,
  BPagination,
  BFormRadio,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import loggingService from '@/service/loggingService'

export default {
  components: {
    BFormRadio,
    flatPickr,
    BTable,
    BCardBody,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BBadge,
    BFormDatepicker,
    BPagination,
    BFormSelect,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      viewMode: 'simple',
      form: {
        username: '',
        roundId: '',
      },
      rowsOptions: [1, 5, 10, 15, 25, 50, 100, 250, 500, 'all'],
      type: '',
      loading: false,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 10,
      },
      columnsSimple: [
        {
          key: 'index',
          label: 'ลำดับ',
          class: 'text-center ',
          thStyle: {
            'max-width': '60px',
            'padding-left': '5px',
            'padding-right': '5px',
          },
        },
        {
          key: 'createAt',
          label: 'วัน/เวลา',
          class: 'text-nowrap',
        },
        {
          key: 'prefix',
          label: 'Prefix',
          class: 'text-nowrap text-right',
        },
        {
          key: 'username',
          label: 'Username',
          class: 'text-nowrap',
        },
        {
          key: 'betId',
          label: 'PoyId',
          class: 'text-nowrap',
        },
        {
          key: 'roundId',
          label: 'RoundId',
          class: 'text-nowrap',
        },
        {
          key: 'bet',
          label: 'BetAmount',
          class: 'text-nowrap',
          formatter: v => this.toNumber(v.toFixed(2)),
        },
        {
          key: 'before',
          label: 'Current',
          class: 'text-nowrap',
          formatter: v => this.toNumber(v.toFixed(2)),
        },
        {
          key: 'winAmount',
          label: 'winAmount',
          class: 'text-nowrap',
        },
        {
          key: 'after',
          label: 'After',
          class: 'text-nowrap',
          formatter: v => this.toNumber(v.toFixed(2)),
        },
        {
          key: 'winLose',
          label: 'Win/Lose',
          class: 'text-nowrap',
        },
        {
          key: 'agent',
          label: 'Agent',
          class: 'text-nowrap',
        },
        {
          key: 'company',
          label: 'Company',
          class: 'text-nowrap',
        },
      ],
      columnsAdvance: [
        {
          key: 'index',
          label: 'ลำดับ',
          class: 'text-center ',
          thStyle: {
            'max-width': '60px',
            'padding-left': '5px',
            'padding-right': '5px',
          },
        },
        {
          key: 'createAt',
          label: 'วัน/เวลา',
        },
        {
          key: 'username',
          label: 'Username',
          class: 'text-nowrap',
        },
        {
          key: 'betId',
          label: 'PoyId',
          class: 'text-nowrap',
        },
        {
          key: 'roundId',
          label: 'RoundId',
          class: 'text-nowrap',
        },
        {
          key: 'status',
          label: 'Result',
          class: 'text-nowrap text-center',
        },
        {
          key: 'bet',
          label: 'Bet',
          class: 'text-nowrap',
          formatter: v => this.toNumber(v.toFixed(2)),
        },
        {
          key: 'winAmount',
          label: 'Cash',
          class: 'text-nowrap',
        },
        {
          key: 'winLose',
          label: 'Win/Lose',
          class: 'text-nowrap',
        },
        {
          key: 'agent',
          label: 'Agent',
          class: 'text-nowrap',
        },
        {
          key: 'company',
          label: 'Company',
          class: 'text-nowrap',
        },
      ],
      data: [],
      startDate: this.$store.state.app.DateFormat(new Date(new Date().setHours(0, 0, 0, 0))),
      endDate: this.$store.state.app.DateFormat(new Date()),
      filter: '',
      username: '',
      summation: {
        beforeBet: 0,
        bet: 0,
        afterBet: 0,
        before: 0,
        winAmount: 0,
        after: 0,
        winlose: 0,
        agent: 0,
        company: 0,
      },
    }
  },
  computed: {
    toNumber() {
      return this.$store.state.app.toNumber
    },
  },
  beforeMount() {
    const view = localStorage.getItem('winLoseViewMode')
    if (view) {
      this.viewMode = view
    }
    this.filter = `&startDate=${this.startDate}&endDate=${this.endDate}`
  },
  async mounted() {
    if (this.$route.query.id !== undefined) {
      this.username = this.$route.query.id
    }
  },
  methods: {
    changeViewMode() {
      localStorage.setItem('winLoseViewMode', this.viewMode)
    },
    textColorWithTwoColor(v) {
      if (v < 0) {
        return 'text-danger'
      }
      if (v > 0) {
        return 'text-success'
      }
      return ''
    },
    textColor(v) {
      if (v < 0) {
        return 'text-danger'
      }
      return ''
    },
    onSubmit() {
      const { username, roundId } = this.form

      this.filter = `&startDate=${this.startDate}&endDate=${this.endDate}`
      if (username !== '') {
        this.filter += `&username=${username}`
      }

      if (roundId !== '') {
        this.filter += `&round=${roundId}`
      }
    },
    // eslint-disable-next-line consistent-return
    async onRequest() {
      let {
        // eslint-disable-next-line prefer-const
        page,
        rowsPerPage,
        // eslint-disable-next-line prefer-const
        rowsNumber,
        // eslint-disable-next-line prefer-const
        sortBy,
        // eslint-disable-next-line prefer-const
        descending,
      } = this.pagination

      if (rowsPerPage === 'all') {
        rowsPerPage = 0
      }
      const startRow = (page - 1) * rowsPerPage
      const fetchCount = rowsPerPage === 0 ? rowsNumber : rowsPerPage

      this.loading = true

      const res = await loggingService.getAuthen(`winlose?limit=${fetchCount}&page=${startRow}${this.filter}`)
      if (res.status === 200) {
        this.data = []

        const {
          list,
          sum,
        } = res.data
        let index = startRow + 1

        this.summation = {
          beforeBet: this.toNumber(sum.beforeBet.toFixed(2)),
          bet: this.toNumber(sum.bet.toFixed(2)),
          afterBet: this.toNumber(sum.afterBet.toFixed(2)),
          before: this.toNumber(sum.before.toFixed(2)),
          winAmount: sum.winAmount,
          after: this.toNumber(sum.after.toFixed(2)),
          winlose: sum.winlose,
          agent: sum.each[0].amount,
        }

        if (sum.each.length >= 1) {
          this.summation.company = 0
          for (let j = 1; j < sum.each.length; j += 1) {
            this.summation.company += sum.each[j].amount
          }
        }

        res.data.list.forEach((v, i) => {
          list[i].agent = v.each[0].amount
          if (v.each.length >= 1) {
            list[i].company = 0
            for (let j = 1; j < v.each.length; j += 1) {
              list[i].company += v.each[j].amount
            }
          }
          list[i].index = index
          index += 1
        })

        this.pagination.page = page
        this.pagination.rowsPerPage = rowsPerPage
        this.pagination.rowsNumber = res.data.count
        this.pagination.sortBy = sortBy
        this.pagination.descending = descending
        this.loading = false

        return res.data.list
      }
    },
    showUserDetial(username) {
      this.$router.push(`members/edit?id=${username}`)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.checkBoxInline {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.table-info > td {
  color: #6e6b7b !important;
}
</style>
